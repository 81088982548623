import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import { css } from 'theme-ui'
import Header from './../components/templateElements/Header'
import BlogCardList, { Blog } from './../components/templateElements/BlogCardList'
import { LayoutContentWrapper } from './../components/layout'
import PageIndicator from './../components/templateElements/PageIndicator'

type TagProps = {
  pageContext: {
    tag: string
    numPages: number
    currentPage: number
  }
  data: {
    blogs: {
      totalCount: number
      edges: Array<{
        node: Blog
      }>
    }
  }
}

const Tags = ({ pageContext, data }: TagProps) => {
  const { tag, numPages, currentPage } = pageContext
  const { edges, totalCount } = data.blogs

  return (
    <>
      <Header label={tag} totalCount={totalCount} />
      <LayoutContentWrapper>
        <div css={css({ pt: '2rem' })}>
          <BlogCardList blogs={edges.map(({ node }) => node)} />
          <PageIndicator
            currentPage={currentPage}
            numPages={numPages}
            prevPath={
              currentPage > 1
                ? currentPage === 2
                  ? `/blog/tag/${_.kebabCase(tag)}`
                  : `/blog/tag/${_.kebabCase(tag)}/${currentPage - 1}`
                : null
            }
            nextPath={
              currentPage < numPages ? `/blog/tag/${_.kebabCase(tag)}/${currentPage + 1}` : null
            }
          />
        </div>
      </LayoutContentWrapper>
    </>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String, $skip: Int!, $limit: Int!) {
    blogs: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt(truncate: true, pruneLength: 80)
          timeToRead
          frontmatter {
            title
            tags
            date(fromNow: true)
            overview
            banner {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
